/* Tabs container */
.custom-tabs-container {
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 5px;
   
}

/* Tab buttons */
.custom-tab-buttons {
    display: flex;
    
}

.custom-tab-buttons button {
    cursor: pointer;
    padding: 10px 20px;
    border: none;
    background-color: #f1f1f1;
    font-size: 16px;
    flex: 1;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s ease;
   
}

.custom-tab-buttons button:hover {
    background-color: #e0e0e0;
}

.custom-tab-buttons button.active {
    background-color: #ccc;
    border-color: #000; /* Border color for active tab */
}

/* Tab content */
.custom-tab-content {
    padding: 20px;
    border-top: 1px solid #ccc;
}
