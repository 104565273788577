/* src/QuestionList.css */
.question-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding: 20px;
  }
  
  .header {
    grid-column: 1 / -1;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    width: 100%;
    border: 1px solid lightblue;
    padding: 3px;
  }
  .question-header{
   
    background-color: white;
   
  
  }
  
  .question-grid, .options-grid, .option-values-grid {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
    max-height: 400px;
    min-height: 400px;
    border: 1px solid lightgray;
    padding:5px;
  }
  
  /* Custom scrollbar styling for webkit-based browsers */
  .question-grid::-webkit-scrollbar,
  .options-grid::-webkit-scrollbar,
  .option-values-grid::-webkit-scrollbar {
    width: 10px; /* Adjust the width of the scrollbar */
  }
  
  .question-grid::-webkit-scrollbar-track,
  .options-grid::-webkit-scrollbar-track,
  .option-values-grid::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
  }
  
  .question-grid::-webkit-scrollbar-thumb,
  .options-grid::-webkit-scrollbar-thumb,
  .option-values-grid::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners */
    border: 0px solid #f1f1f1; /* Adds a small border around the thumb */
  }
  
  /* Custom scrollbar styling for Firefox */
  .question-grid,
  .options-grid,
  .option-values-grid {
    scrollbar-width: thin; /* Thin scrollbar */
    scrollbar-color: #888 #f1f1f1; /* Thumb color, Track color */
  }
  
  .question-item, .option-item, .value-item {
    display: flex;
    flex-direction: column;
    gap: 1px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    
  }
  
  .question-title {
    font-weight: 600;
    padding-bottom: 11px;
  }
  
  .question-sub-title {
    font-weight: 600;
    margin-right: 10px;
    text-decoration: underline;
    font-size: 13px;
  }
  .question-sub-value {
    
    
    text-align: left;
    font-size: 13px;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .MuiToolbar-regular {
    z-index: 1 !important;
  }
  
  .modal-content {
    z-index: 9;
    background: white;
    width: 500px;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .modal-content label {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  @media (max-width: 1024px) {
    .question-list {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media (max-width: 768px) {
    .question-list {
      grid-template-columns: 1fr;
    }
  }
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none; /* Hidden by default */
  }
  
  @keyframes spin {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
  }
  .question-option-link
  {
    color: blue;
    text-align: right;
    text-decoration: underline;
    cursor: pointer;
  }
  .question-buttons
  {
    color: blue;
    text-align: right;
    text-decoration: underline;
    cursor: pointer;
    padding-right: 10px;
  }