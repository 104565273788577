.pick-station-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    height: 100vh;
}
.pick-station-heading{
    text-align: center;
    margin:10px;
    font-size: 18px;
    font-weight: bold;
}
.station-pick-inner-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.station-parent {
    padding: 10px;
    margin: 10px;
    background-color: #aaa;
    border: 2px solid #999;
    cursor: pointer;
    text-align: center;
}

.station-image img {
    width: 64px;
    height: 64px;
}

.station-name {
    font-weight: bold;
}

.station-user {
    font-weight: bold;
    font-weight: blue;
}

.station-status-available{
    color:green;
    font-weight: bold;
}
.station-status-locked{
    color:red;
    font-weight: bold;
}